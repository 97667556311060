import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "IngresoTesoreriaActualcinPage",
    data() {
        let ss = new MainService();
        return {
            msg: "IngresoPresupuestarioActualPage",
            ss: ss,
            auth: {},
            ajax: {
                "url": ss.indexIngresoPresupuestarioActual(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'IngresoPresupuestario', name: 'IngresoPresupuestario', title: 'Ingreso Presupuestario' },
                { data: 'Anexo', name: 'Anexo', title: 'Anexo' },
                { data: 'Fecha', name: 'Fecha', title: 'Fecha' },
                { data: 'Gestion', name: 'Gestion', title: 'Gestión' },
                { data: 'Concatenado', name: 'Concatenado', title: 'Nº Documento' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            ajaxDetalle: {
                "url": ss.indexIngresoPresupuestarioDetalle(),
                headers: ss.getToken(),
            },
            columnsDetalle: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'NroDevengado', name: 'ipd.NroDevengado', title: 'Nro Devengado' },
                { data: 'Reparticion', name: 'r.Reparticion', title: 'Repartición' },
                { data: 'Rubro', name: 'u.Rubro', title: 'Rubro' },
                { 
                    data: 'FechaDeposito', 
                    name: 'ipd.FechaDeposito', 
                    title: 'Fecha Deposito',
                    render: function(data) {
                        // Formatear la fecha en un formato legible
                        return new Date(data).toLocaleDateString(); // Puedes usar otras funciones de formato según tu preferencia
                    }
                },
                //{ data: 'FechaDeposito', name: 'ipd.FechaDeposito', title: 'Fecha Deposito' },
                { data: 'Monto', name: 'ipd.Monto', title: 'Importe' },
                { data: 'Glosa', name: 'ipd.Glosa', title: 'Glosa' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            personas: [],
            ingresoPresupuestarios: [],
            ingresopresupuestario: {},
            archivo: {},
            ingresopresupuestariodetalle: {},
            ingresosPresupuestarios: [],
            rubros: [],
            categoriasProgramaticas: [],
            isLoading: false,
            errorBag: {},
            rutaApp:process.env.VUE_APP_MAIN_SERVICE,
            reporte: {},
            rubrosHijos:[],
            rubrosPadres:[],
            ingresoscdet: [],
            searchIngresoscDetalle: "",
            selectedIngresoscDetalle:null,
            reparticiones: [],
            searchReparticion: "",
            selectedReparticion: null,
            selectedItems: [],
        };
    },
    methods: {
        getPersona() {
            this.ss.listPersona().then(
                (result) => {
                    let response = result.data;
                    this.personas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getReparticion() {
            this.ss.listReparticion().then(
                (result) => {
                    let response = result.data;
                    this.reparticiones = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getRubro() {
            this.ss.listRubro().then(
                (result) => {
                    let response = result.data;
                    this.rubros = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        getRubroPadre() {
            this.ss.listRubroPadre().then(
                (result) => {
                    let response = result.data;
                    this.rubrosPadres = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        getRubroHijo(id) {
            // console.log(id);
            this.ss.listRubroHijo(id).then(
                (result) => {
                    let response = result.data;
                    this.rubrosHijos = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getCategoriaProgramatica() {
            this.ss.listCategoriaProgramatica().then(
                (result) => {
                    let response = result.data;
                    this.categoriasProgramaticas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        newIngresoPresupuestario() {
            this.ingresopresupuestario = {};
            this.$refs['frm-ingresopresupuestario'].show();
        },
        newIngresoPresupuestarioPorDia(){
            this.ingresopresupuestario = {};
            this.$refs['frm-ingresopresupuestario-por-dia'].show();
        },
        cancelIngresoPresupuestarioPorDia() {
            this.$refs['frm-ingresopresupuestario-por-dia'].hide();
        },

        cancelIngresoPresupuestarioDetalle(){
            this.$refs['frm-ingresopresupuestariodetalle'].hide();

        },
        saveIngresoPresupuestarioPorDia(){
            this.ss.storeIngresoPresupuestarioPorDia(this.ingresopresupuestario).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-ingresopresupuestario-por-dia'].hide();
                    this.$refs['datatable-ingresopresupuestario'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        showIngresoPresupuestario(id) {
            this.isLoading=true;
            // this.aplicarFiltroDetalle(id);
            this.ss.showIngresoPresupuestario(id).then(
                (result) => {
                    let response = result.data;
                    this.ingresopresupuestario = response.data;
                    this.$refs['view-ingresopresupuestario'].show();
                    this.isLoading=false;
                    this.aplicarFiltroDetalle(id);
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editIngresoPresupuestario() {
            this.$refs['frm-ingresopresupuestario'].show();
            this.$refs['view-ingresopresupuestario'].hide();
        },
        cancelIngresoPresupuestario() {
            if (this.ingresopresupuestario.id) {
                this.$refs['view-ingresopresupuestario'].show();
            }
            this.$refs['frm-ingresopresupuestario'].hide();
        },
        saveIngresoPresupuestario() {
            this.ss.storeIngresoPresupuestario(this.ingresopresupuestario).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-ingresopresupuestario'].hide();
                    this.$refs['datatable-ingresopresupuestario'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteIngresoPresupuestario() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyIngresoPresupuestario(this.ingresopresupuestario)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-ingresopresupuestario'].hide();
                                this.$refs['datatable-ingresopresupuestario'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        newIngresoPresupuestarioDetalle() {
            this.ingresopresupuestariodetalle = {};
            this.$refs['frm-ingresopresupuestariodetalle'].show();
        },
        saveIngresoPresupuestarioDetalle() {
            this.ingresopresupuestariodetalle.array = []; 
            this.ingresopresupuestariodetalle.IngresoPresupuestario = this.ingresopresupuestario.id;
            this.ingresopresupuestariodetalle.Gestion = this.ingresopresupuestario.Gestion;
            this.ingresopresupuestariodetalle.array = this.selectedItems;
            this.ss.storeIngresoPresupuestarioDetalle(this.ingresopresupuestariodetalle).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-ingresopresupuestariodetalle'].hide();
                    this.$refs['datatable-ingresopresupuestarioDetalle'].reload();
                    this.selectedItems = [];
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        showIngresoPresupuestarioDetalle(id) {
            this.isLoading=true;
            this.ss.showIngresoPresupuestarioDetalle(id).then(
                (result) => {
                    let response = result.data;
                    this.ingresopresupuestariodetalle = response.data;
                    this.$refs['view-ingresopresupuestariodetalle'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editIngresoPresupuestarioDetalle() {
            this.$refs['frm-ingresopresupuestariodetalle'].show();
            this.$refs['view-ingresopresupuestariodetalle'].hide();
        },
        deleteIngresoPresupuestarioDetalle() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyIngresoPresupuestarioDetalle(this.ingresopresupuestariodetalle)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-ingresopresupuestariodetalle'].hide();
                                this.$refs['datatable-ingresopresupuestarioDetalle'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },

        excelIngresoPresupuestarioDetalle(){
            let url = "api/IngresoPresupuestarioDetalle/get_excel?IngresoPresupuestario="+this.ingresopresupuestario.id;
            console.log(this.rutaApp+url);
            window.open(this.rutaApp+url);
        },
        aplicarFiltroDetalle(){
            console.log("se aplican los filtros");
            let idIngresoPresupuestario = this.ingresopresupuestario.id;
            this.ajaxDetalle.data = function (d) {
                d.IngresoPresupuestario = idIngresoPresupuestario;
            }
            this.$refs['datatable-ingresopresupuestarioDetalle'].reloadAjax(this.ajaxDetalle);
            // window.e2 = this.$refs["datatable-ingresopresupuestarioDetalle"];
        },
        draw() {
            window.$('.btn-datatable-IngresoPresupuestario').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showIngresoPresupuestario(data);
            });
        },
        drawDetalle() {
            window.$('.btn-datatable-IngresoPresupuestarioDetalle').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                console.log(data);
                this.showIngresoPresupuestarioDetalle(data);
            });
        },
        abrirModalReporte(){
            this.$refs['frm-reporte'].show();
        },
        cancelarModalReporte(){
            this.reporte = {};
            this.$refs['frm-reporte'].hide();
        },

        abrirModalCierreArchivo(){
            this.$refs['modalCierreArchivo'].show();
        },

        reporteporS() {
            console.log("reporte comprobanteContable", this.ingresopresupuestario.id);
            this.ss.imprimirIngresoS(this.ingresopresupuestario.id)
                .then( 
                    result => {
                    let response = result.data;
                    var urlFile = response.data;
                    console.log(urlFile);
                    console.log("-------------------------------------------------------");
                    console.log(this.rutaApp);
                    var dir =this.rutaApp + urlFile.url;
                    console.log("-------------------------------------------------------");
                    console.log(dir);
                    window.open(dir);
                })
                .catch( error => {
                    console.log(error);
                    this.isLoading=false;
                })  
       
        },

        generarReporte(){
            console.log("genera reporte");
            let direccion = "api/IngresoPresupuestarioDetalle/get_excel";
            var url = new URL(this.rutaApp+direccion);
            if(this.reporte.CategoriaProgramatica){
                url.searchParams.append('CategoriaProgramatica', this.reporte.CategoriaProgramatica);
            }
            if(this.reporte.Reparticion){
                url.searchParams.append('Reparticion', this.reporte.Reparticion);
            }
            if(this.reporte.Rubro){
                url.searchParams.append('Rubro', this.reporte.Rubro);
            }
            if(this.reporte.FechaInicio){
                url.searchParams.append('FechaInicio', this.reporte.FechaInicio);
            }
            if(this.reporte.FechaFin){
                url.searchParams.append('FechaFin', this.reporte.FechaFin);
            }
            window.open(url.href);
            console.log(url.href);
        },

        generarReporteFiltroPdf(){

            console.log("datos reporte",this.reporte);

            this.ss.imprimirIngresoFechas(this.reporte)
                .then( 
                    result => {
                    let response = result.data;
                    console.log(response);

                    // var urlFile = response.data;
                    // console.log(urlFile);
                    // console.log("-------------------------------------------------------");
                    // console.log(this.rutaApp);
                    // var dir =this.rutaApp + urlFile.url;
                    // console.log("-------------------------------------------------------");
                    // console.log(dir);
                    // window.open(dir);
                })
                .catch( error => {
                    console.log(error);
                    this.isLoading=false;
                })  
        },
        saveCierreArchivo() {
            this.archivo.id = this.ingresopresupuestario.id
            this.archivo.Fojas = this.ingresopresupuestario.Fojas
            this.archivo.FechaCierreArchivo = this.ingresopresupuestario.FechaCierreArchivo
            this.archivo.Observaciones = this.ingresopresupuestario.Observaciones
            this.archivo.Activo = this.ingresopresupuestario.Activo
            this.ss.storeCierreArchivo(this.archivo ).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-descargo'].reload();
                    this.$refs['datatable-ingresopresupuestario'].reload();
                    this.$refs['archivo'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        printCierreArchivo() {
            console.log("id al reporte archuvo",this.ingresopresupuestario.id);

            this.ss.printCierreArchivo(this.ingresopresupuestario.id)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        serializeItem(item) {
            if (item.Reconocido) {
              return `${item.NroDeposito}: ${item.Glosa}: ${item.Credito} Bs. YA FUE RECONOCIDO`;
            } else {
              return `${item.NroDeposito}: ${item.Glosa}: ${item.Credito}`;
            }
          },
          addSelection(item) {
            // Verifica si el elemento ya está seleccionado
            if (!this.selectedItems.some(selectedItem => selectedItem.id === item.id)) {
              this.selectedItems.push(item);
            }
            // Limpia el campo de búsqueda
            this.searchIngresoscDetalle = '';
          },
          removeSelection(item) {
            // Remueve el elemento del arreglo de seleccionados
            this.selectedItems = this.selectedItems.filter(selectedItem => selectedItem.id !== item.id);
          },
        
    },
    watch: {
        searchIngresoscDetalle(q) {
            this.ss.select2Ingresoscdetalle(q).then((res) => {
                console.log(res);
                if (res.data.success) {
                    this.ingresoscdet = res.data.items;
                } else {
                    this.ingresoscdet = [];
                }
            })
        },
        searchReparticion(q) {
            this.ss.select2Reparticion(q).then((res) => {
                console.log("",res);
                if (res.data.success) {
                   
                    this.reparticiones = res.data.items;
                
                } else {
                    this.reparticiones = [];
                }
            })
        },
   
    },
   
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        this.auth = JSON.parse(localStorage.getItem("persona"));
        if (!persona) {
          this.$router.push('/Login');
        } else {
            this.getPersona();
            this.getReparticion();
            this.getRubro();
            this.getRubroPadre();
            // this.getRubroHijo();
            this.getCategoriaProgramatica();
        }
    }
};
